import axios from "axios";

const hostname = "https://cpchgce929.execute-api.us-west-2.amazonaws.com/dev/api";

export function endpointFrom(path) {
    return hostname + "/" + (typeof(path) === "string" ? path : path.join("/"));
}

export async function makeRequest(path, method, payload, isAuth = false) {
    try {
        let params = {
            method: method,
            url: endpointFrom(path),
            headers: {
                "Content-Type": "application/json"
            }
        };

        if (isAuth) {
            const token = window.localStorage.getItem("token");
            params.headers["Authorization"] = "Bearer " + token;
        }

        if (payload) {
            if (method.toLowerCase() === "get") {
                params["params"] = payload;
            } else if (method.toLowerCase() === "post") {
                params["data"] = payload;
            }
        }

        let response = await axios(params);
        return response;
    } catch(e) {
        return null;
    }
}

export async function authRequestWith(path, method, payload) {
    return makeRequest(path, method, payload, true);
}

export async function requestWith(path, method, payload) {
    return makeRequest(path, method, payload, false);
}