// React
import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// Resources
import Colors from '../resources/Colors';

class AlertModal extends Component {
	render() {
		const open = Radium.keyframes({
			'0%': {
				borderRadius: 0,
				height: 0,
				width: 0
			},
			'100%': {
				borderRadius: '100vh',
				height: '200vh',
				width: '200vh'
			}
		}, 'open');
		const pop = Radium.keyframes({
			'0%': {
				opacity: 0,
				'-webkit-transform': 'scale(.3)',
				transform: 'scale(.3)'
			},
			'25%': {
				opacity: 0,
				'-webkit-transform': 'scale(.3)',
				transform: 'scale(.3)'
			},
			'50%': {
				opacity: 1,
				'-webkit-transform': 'scale(1.0)',
				transform: 'scale(1.0)'
			},
			'70%': {
				'-webkit-transform': 'scale(0.9)',
				transform: 'scale(0.9)'
			},
			'100%': {
				'-webkit-transform': 'scale(1)',
				transform: 'scale(1)'
			}
		}, 'pop');
		const styles = {
			alert: {
				animation: 'x 0.5s linear',
				animationName: pop,
				background: Colors.White,
				borderRadius: "3px",
				padding: "25px 40px",
				width: "300px",
				zIndex: 1
			},
			background: {
				animation: 'x 0.5s linear',
				animationName: open,
				background: 'rgba(0, 0, 0, 0.70)',
				height: '100vh',
				position: 'absolute',
				width: '100vw'
			},
			buttons: {
				display: 'flex',
				justifyContent: "space-between"
			},
			cancelable: {
				height: '100vh',
				left: 0,
				position: 'fixed',
				top: 0,
				width: '100%',
				zIndex: 0
			},
			cancelButton: {
				background: Colors.MediumGray,
				borderRadius: "2px",
				color: Colors.White,
				cursor: "pointer",
				fontFamily: 'CeraPro',
				fontSize: "17px",
				fontWeight: 500,
				padding: "10px 0",
				textAlign: "center",
				width: "calc(50% - 10px)",
				":hover": {
					background: Colors.MediumGrayHover
				}
			},
			container: {
				alignItems: 'center',
				display: 'flex',
				height: '100vh',
				justifyContent: 'center',
				left: 0,
				margin: 0,
				position: 'fixed',
				top: 0,
				width: '100%',
				zIndex: 10
			},
			message: {
				fontFamily: 'CeraPro',
				fontSize: "20px",
				fontWeight: 500,
				lineHeight: "30px",
				marginBottom: "20px",
				marginTop: 0
			},
			yesButton: {
				background: Colors.Green,
				borderRadius: "2px",
				color: Colors.White,
				cursor: "pointer",
				fontFamily: 'CeraPro',
				fontSize: "17px",
				fontWeight: 500,
				padding: "10px 0",
				textAlign: "center",
				width: "calc(50% - 10px)",
				":hover": {
					background: Colors.GreenHover
				}
			}
		};

		return (
			<div style={styles.container}>
				<div style={styles.background} />
				<div style={styles.alert}>
					<h1 style={styles.message}>Would you like to connect with <strong>{this.props.company}</strong>?</h1>
					<div style={styles.buttons}>
						<div onClick={this.props.cancelConnection} style={styles.cancelButton} key="no">No</div>
						<div onClick={this.props.makeConnection} style={styles.yesButton} key="yes">Yes!</div>
					</div>
				</div>
				<div onClick={this.props.cancelConnection} style={styles.cancelable} />
			</div>
		);
	}

	optionalGet(data, key, alt) {
		try {
			return data[key] || alt;
		} catch (e) {
			return alt;
		}
	}
}

export default Radium(AlertModal);