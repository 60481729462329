import { authRequestWith } from './Config';

export async function isLive() {
	try {
		let res = await authRequestWith("isLive", "post");
		if (res.status > 300) {
			return {
				live: false,
				success: false
			};
		} else {
			const live = res.data["live"];
			return {
				live: live === 'active',
				success: true
			};
		}
	} catch (e) {
		return {
			live: false,
			success: false
		};
	}
}

export async function startTime() {
	try {
		let res = await authRequestWith("startTime", "post");
		if (res.status > 300) {
			return {
				live: false,
				success: false
			};
		} else {
			const time = res.data["time"];
			return {
				time: time,
				success: true
			};
		}
	} catch (e) {
		return {
			time: false,
			success: false,
			error: e.message
		};
	}
}

export async function sendBlast(key) {
	try {
		let res = await authRequestWith("sendBlast", "post", {
			key: key
		});
		if (res.status > 300) {
			return false;
		} else {
			return true;
		}
	} catch (e) {
		return false;
	}
}

export async function toggleLive(value) {
	try {
		let res = await authRequestWith("toggleLive", "post", {
			toggle: value ? 'active' : 'inactive'
		});
		if (res.status > 300) {
			return false;
		} else {
			return true;
		}
	} catch (e) {
		return false;
	}
}

export async function setStatus(name, value) {
	try {
		let res = await authRequestWith("set-status", "post", {
			name: name,
			value: value
		});
		if (res.status > 300) {
			return false;
		} else {
			return true;
		}
	} catch (e) {
		return false;
	}
}

export async function connections(year) {
	try {
		let res = await authRequestWith("connections", "post", {
			year: year
		});
		if (res.status > 300) {
			return [];
		} else {
			return res.data;
		}
	} catch (e) {
		return [];
	}
}

export async function saveCompanyWith(id, data) {
	try {
		let res = await authRequestWith("modify-company", "post", {
			data: data,
			id: id
		});
		if (res.status > 300) {
			return [];
		} else {
			return res.data;
		}
	} catch (e) {
		return [];
	}
}