import { authRequestWith } from './Config';

export async function getCurrent() {
	let res = await authRequestWith("watch", "get");
	if (!res || res.status > 300) {
		return false;
	} else {
		let current = await res.data;
		// if (current["status"] === "inactive") return "inactive";
		return current;
	}
};