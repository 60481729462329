// React
import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// Resources
import Colors from '../resources/Colors';

class Loading extends Component {
	render() {
		const keyframes = Radium.keyframes({
			'0%': {
				opacity: 0,
				'-webkit-transform': 'scale(.3)',
				transform: 'scale(.3)'
			},
			'50%': {
				opacity: 1,
				'-webkit-transform': 'scale(1.0)',
				transform: 'scale(1.0)'
			},
			'70%': {
				'-webkit-transform': 'scale(0.9)',
				transform: 'scale(0.9)'
			},
			'100%': {
				'-webkit-transform': 'scale(1)',
				transform: 'scale(1)'
			}
		}, 'bounce');
		const styles = {
			container: {
				alignItems: 'center',
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				width: '100%'
			},
			circle: {
				animation: 'x 0.8s linear infinite',
				animationName: keyframes,
				background: Colors.Green,
				borderRadius: '50px',
				height: '100px',
				width: '100px'
			}
		};

		return (
			<div style={styles.container}>
				<div style={styles.circle} />
			</div>
		);
	}
}

export default Radium(Loading);