// Libraries
import React, { Component } from 'react';

// API
import { getCurrent } from '../resources/Watch.js';

// Images
import logo from "../images/logo.png";

import styles from "./styles/watchPage.module.scss";

export default class Watch extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        this.loadData();
        this.monitor = setInterval(async () => {
			try {
				this.loadData();
			} catch (e) {
				console.log(e);
			}
		}, 10000);
    }

    loadData = async () => {
        let data = await getCurrent();
        if (!data.presenting) {
            window.location.href = "/";
        }
        this.setState(data);
    }

    render() {
        let presenting = this.state.presenting ? this.state.presenting.name : '';
        let presentingLink = this.state.presenting ? this.state.presenting.permalink : '';
        let streamLink = this.state.stream;
        return (
            <div className={styles.container}>
                <div className={styles.padding}>
                    <a href={"https://demoday.pear.vc/company/" + presentingLink} target="_blank"><h1>{presenting}</h1></a>
                </div>
                <iframe className={styles.iframe} frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" height="100%" type="text/html" src={streamLink}></iframe>
                <div className={styles.padding} />
            </div>
        );
    }
}