// React
import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// Resources
import Colors from '../resources/Colors';

const demoDay = '2023-10-5T16:20:00.000Z';

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timeRemaining: (new Date(demoDay) - new Date())
        }

        this.setTime = this.setTime.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(this.setTime, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    setTime() {
        this.setState({
            timeRemaining: (new Date(demoDay) - new Date())
        });
        if (this.state.timeRemaining <= 0) {
            window.location.reload();
        }
    }

    render() {
        let { timeRemaining } = this.state;
        let day_ms = 1000*60*60*24;
        let hour_ms = 1000*60*60;
        let minute_ms = 1000*60;
        let ms = 1000;
        let days = Math.floor(timeRemaining / (day_ms));
        let hours = Math.floor((timeRemaining % day_ms) / hour_ms);
        let minutes = Math.floor((timeRemaining % hour_ms) / minute_ms);
        let seconds = Math.floor((timeRemaining % minute_ms) / ms);
        
        let bigVisible = true;
        let smallVisible = true;
        if (hours > 0) {
            smallVisible = false;
        } else {
            bigVisible = false;
        }

        const styles = {
            bigValues: {
                '@media only screen and (max-width: 500px)': {
                    display: bigVisible ? 'flex' : 'none'
                }
            },
            button: {
				alignItems: 'center',
				background: Colors.Green,
				border: 'none',
				borderRadius: '3px',
                color: '#FFFFFF',
                cursor: 'pointer',
				display: 'flex',
				fontFamily: 'CeraPro',
				fontSize: '15px',
				fontWeight: 500,
				outline: 'none',
                padding: '15px 25px',
                ':hover': {
                    background: Colors.GreenHover
                }
			},
            container: {
                alignItems: 'center',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                marginTop: '50px',
                '@media only screen and (max-width: 500px)': {
                    
                }
            },
            content: {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            countdown: {
                display: 'flex'
            },
            message: {
                fontFamily: 'Financier',
				fontSize: '20px',
                fontWeight: 500,
                margin: 0,
                padding: 0,
                textAlign: 'center',
                textTransform: 'uppercase',
                '@media only screen and (max-width: 500px)': {
                    fontSize: '17px'
                }
            },
            timeItem: {
                alignItems: 'flex-start',
                display: 'flex',
                padding: '0 10px'
            },
            timeLabel: {
                color: Colors.Gray,
                fontFamily: 'Financier',
				fontSize: '35px',
                fontWeight: 500,
                lineHeight: '35px',
                padding: '0 0 0 5px',
                '@media only screen and (max-width: 500px)': {
                    fontSize: '25px'
                }
            },
            timeValue: {
                color: Colors.Green,
                fontFamily: 'Financier',
				fontSize: '40px',
				fontWeight: 600,
                lineHeight: '35px',
                maxWidth: '50px',
                padding: 0,
                '@media only screen and (max-width: 500px)': {
                    fontSize: '30px',
                    maxWidth: '35px'
                }
            },
            smallValues: {
                '@media only screen and (max-width: 500px)': {
                    display: smallVisible ? 'flex' : 'none'
                }
            },
            submessage: {
                fontFamily: 'CeraPro',
				fontSize: '17px',
                fontWeight: 500,
                margin: 0,
                padding: '0 0 15px 0',
                textTransform: 'uppercase'
            }
        };
        return (
            <div style={styles.container}>
                <div style={styles.content}>
                    <h1 style={styles.message}>Companies will appear here in</h1>
                    {/* <h2 style={styles.submessage}>Time until Demo Day</h2> */}
                    <div style={styles.countdown}>
                        <span style={[styles.timeItem, styles.bigValues]}><h1 style={styles.timeValue}>{days}</h1><h2 style={styles.timeLabel}>d</h2></span>
                        <span style={[styles.timeItem, styles.bigValues]}><h1 style={styles.timeValue}>{hours}</h1><h2 style={styles.timeLabel}>h</h2></span>
                        <span style={[styles.timeItem, styles.smallValues]}><h1 style={styles.timeValue}>{minutes}</h1><h2 style={styles.timeLabel}>m</h2></span>
                        <span style={[styles.timeItem, styles.smallValues]}><h1 style={styles.timeValue}>{seconds}</h1><h2 style={styles.timeLabel}>s</h2></span>
                    </div>
                    <h2 style={styles.submessage}>Doors open for rehearsal at 9:50am</h2>
                    <button onClick={this.props.openTrailer} style={styles.button}>Watch Trailer</button>
                </div>
            </div>
        );
    }
}

export default Radium(Countdown);