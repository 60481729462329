import { authRequestWith } from './Config';

export async function getCompanies() {
	let res = await authRequestWith("companies", "get");
	if (!res || res.status >= 300) {
		return false;
	} else {
		let companies = await res.data;
		// if (companies["status"] === "inactive") return "inactive";
		return companies;
	}
};

export async function getCompany(id) {
	let res = await authRequestWith("company", "get", {
		id: id
	});
	if (res.status > 300) {
		return false;
	} else {
		let company = res.data;
		return company;
	}
}

export async function getCompanyWith(permalink) {
	let res = await authRequestWith("company-with", 'get', {
		permalink: permalink
	});
	if (res.status > 300) {
		return false;
	} else {
		let company = res.data;
		return company;
	}
}

export async function makeConnection(id) {
	let res = await authRequestWith("connect", 'post', {
		id: id
	});
	if (res.status === 403) {
		return 0;
	} else if (res.status > 300) {
		return -1;
	} else {
		return 1;
	}
}

export async function checkStatus() {
	let res = await authRequestWith("status", 'get');
	if (res.status > 300) {
		return null;
	} else {
		return res.data;
	}
}

export async function videoStats(id, action) {
	let res = await authRequestWith("video", 'post', {
		id: id,
		action: action
	});
	if (res.status === 403) {
		return 0;
	} else if (res.status > 300) {
		return -1;
	} else {
		return 1;
	}
}