// Libraries
import React, { Component } from 'react';

// Images
import logo from "../images/logo.png";
import dayjs from 'dayjs';

import styles from "./styles/inactivePage.module.css";

// Get time function
import { startTime } from "../resources/Admin";

export default class InactivePage extends Component {
    refresh = () => {
        window.location.reload();
    }

    constructor(props) {
        super(props);
		this.state = {
			startTimeEpoch: null,
            startTimeMessage: "PearX Demo day has not started yet."
		};
	}

    async getStartTime(){
        let response = await startTime();
        if (response.success) {
            console.log(response);
            this.setState({
                startTimeEpoch: response.time,
                startTimeMessage: "Demo Day will start at " + dayjs(parseInt(response.time)).format(' h:mm a on MMMM D, YYYY')
    
            });
        } else {
            console.log(response);
        }
    }

    componentDidMount() {
        this.getStartTime();
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.main}>
                    <img alt="logo" src={logo} />
                    <h1> {this.state.startTimeMessage} </h1>
                    <h2>You're logged in as <strong>{localStorage.getItem("name")}</strong></h2>
                    {/* <h2>The Zoom link will be published here at 10am PT</h2> */}
                    <button onClick={this.refresh}>Refresh Page</button>
                </div>
            </div>
        );
    }
}