// Libraries
import React, { Component } from 'react';

import InactivePage from './InactivePage';

// Libraries
import Radium from 'radium';

// Components
import AlertModal from '../components/modal';
import Companies from '../components/companies';
import Countdown from '../components/countdown';
import Footer from '../components/footer';
import Header from '../components/header';
import Loading from '../components/loading';
import SlideModal from '../components/slideout';

// API
import { checkStatus, getCompanies, getCompany, getCompanyWith, makeConnection } from '../resources/Companies';

/* This is the home */
class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalActive: false,
			companies: [],
			selectedCompany: null,
			selectedCompanyId: null,
			slideoutActive: false,
			zoomLink: null,
			status: null,
			listening: false
		};

		this.mainLayout = React.createRef();
		// this.events = new EventSource('/api/status');

		this.close = this.close.bind(this);
		this.cancelConnectCompany = this.cancelConnectCompany.bind(this);
		this.closeTrailer = this.closeTrailer.bind(this);
		this.connectCompany = this.connectCompany.bind(this);
		this.loadData = this.loadData.bind(this);
		this.openCompany = this.openCompany.bind(this);
		this.openCompanyConnectionModal = this.openCompanyConnectionModal.bind(this);
		this.openCompanyWith = this.openCompanyWith.bind(this);
		this.openTrailer = this.openTrailer.bind(this);
	}

	componentDidMount() {
		this.loadData();
		if (this.props.id) {
			this.openCompanyWith(this.props.id);
		}

		this.monitor = setInterval(async () => {
			try {
				let statusCheck = await checkStatus();
				let { presenting, status, zoom } = statusCheck;
				if (this.state.status === "inactive" && status === "active") {
					this.setState({ status: status });
					this.loadData();
				}
				this.setState({
					zoomLink: zoom,
					presenting: presenting
				});
			} catch (e) {
				console.log(e);
			}
		}, 10000);
	}
	
	componentWillUnmount() {
		if (this.monitor) {
			clearInterval(this.monitor);
		}
	}

	render() {
		let widthAdjust = false;
		if (this.mainLayout && this.mainLayout.current && this.state.slideoutActive && this.mainLayout.current.offsetWidth <= 1500) {
			widthAdjust = true;
		}
		const styles = {
			base: {
				display: 'flex',
				flexDirection: 'row'
			},
			container: {
				borderLeft: "10px solid #B1C640",
				borderRight: "10px solid #B1C640",
				display: this.state.companies === 'inactive' ? 'flex' : 'block',
				flex: 2,
				height: '100vh',
				'@media only screen and (max-width: 1300px)': {
					borderLeft: "5px solid #B1C640",
					borderRight: "5px solid #B1C640",
					flex: 3
				}
			},
			content: {
				alignItems: this.state.status === 'inactive' ? 'normal' : 'center',
				display: this.state.status === 'inactive' ? 'flex' : 'block',
				flex: this.state.status === 'inactive' ? 1 : 0,
				flexDirection: 'column',
				justifyContent: this.state.status === 'inactive' ? 'space-between' : 'center',
				padding: !widthAdjust ? "0 150px 50px 150px" : "0 50px 50px 50px",
				'@media only screen and (max-width: 1300px)': {
					padding: "0 25px 50px 25px"
				}
			},
			contentScroll: {
				display: this.state.status === "inactive" ? 'flex' : 'block',
				flexDirection: 'column',
				height: '100vh',
				overflowY: 'scroll',
				width: this.state.companies === "inactive" ? '100%' : 'auto',
				WebkitOverflowScrolling: 'touch'
			},
			trailer: {
				alignItems: 'center',
				background: 'rgba(0, 0, 0, 0.7)',
				display: 'flex',
				height: '100vh',
				justifyContent: 'center',
				left: 0,
				position: 'fixed',
				top: 0,
				width: '100%',
				zIndex: 100
			},
			trailerClose: {
				height: '100vh',
				cursor: 'pointer',
				left: 0,
				position: 'absolute',
				top: 0,
				width: '100%',
				zIndex: 30
			},
			video: {
				height: '45vw',
				maxHeight: '80vh',
				width: '80vw',
				zIndex: 100
			}
		};

		return (
			<div style={styles.base}>
				{ this.state.modalActive &&
					<AlertModal
						company={this.state.connectingCompanyName}
						makeConnection={this.connectCompany}
						cancelConnection={this.cancelConnectCompany}
					/>
				}
				{ this.state.trailer &&
					<div style={styles.trailer}>
						<div onClick={this.closeTrailer} style={styles.trailerClose} />
						<iframe style={styles.video} width="560" height="315" src="https://www.youtube.com/embed/FWaUq7-RTZA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>
				}
				{ this.state.status !== 'inactive' ? (
					<div ref={this.mainLayout} style={styles.container}>
						<div style={styles.contentScroll}>
							<div style={styles.content}>
								{ this.state.status &&
									<Header
										name={localStorage.getItem("name")}
										status="active"
										zoomLink={this.state.zoomLink}
									/>
								}
								{ this.state.status !== "inactive" &&
									<Companies
										connectCompany={this.openCompanyConnectionModal}
										data={this.state.companies}
										openCompany={this.openCompany}
										presenting={this.state.presenting}
									/>
								}
								{ this.state.status === "inactive" &&
									<Countdown
										openTrailer={this.openTrailer}
									/>
								}
								{ !this.state.status &&
									<Loading />
								}
							</div>
							{ this.state.status &&
								<Footer />
							}
						</div>
					</div>
				) : (
					<InactivePage />
				)}
				{ this.state.slideoutActive &&
					<SlideModal
						connectCompany={this.openCompanyConnectionModal}
						close={this.close}
						data={this.state.selectedCompany}
					/>
				}
			</div>
		);
	}

	close() {
		this.setState({
			slideoutActive: false,
			selectedCompany: null,
			selectedCompanyId: null
		});
	}

	async loadData() {
		let companies = await getCompanies();
		this.setState({
			companies: companies["data"],
			zoomLink: companies["zoomLink"],
			presenting: companies["presenting"],
			status: companies["status"] || "inactive"
		});
		if (this.state.selectedCompany && this.state.slideoutActive) {
			let companyData = await getCompany(this.state.selectedCompanyId);
			this.setState({
				selectedCompany: companyData
			});
		}
	}

	async openCompanyConnectionModal(id, name) {
		await this.setState({modalActive: true, connectingCompany: id, connectingCompanyName: name});
	}

	async connectCompany() {
		let connect = await makeConnection(this.state.connectingCompany);
		if (connect === 1) {
			this.loadData();
		}
		this.cancelConnectCompany();
	}

	async cancelConnectCompany() {
		await this.setState({modalActive: false, connectingCompany: null, connectingCompanyName: null});
	}

	async openCompany(id) {
		if (this.state.selectedCompany && this.state.selectedCompany["id"] === id) {
			this.close();
			return;
		}

		let companyData = await getCompany(id);
		this.setState({
			slideoutActive: true,
			selectedCompany: companyData,
			selectedCompanyId: id
		});
	}

	async openCompanyWith(permalink) {
		let companyData = await getCompanyWith(permalink);
		this.setState({
			slideoutActive: true,
			selectedCompany: companyData,
			selectedCompanyId: companyData.id
		});
	}

	openTrailer() {
		this.setState({ trailer: true });
	}

	closeTrailer() {
		this.setState({ trailer: false });
	}
}

export default Radium(Home);