import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// Resources
import Colors from '../resources/Colors';

/* This is the loading */
class Loading extends Component {
	render() {
		const styles = {
			container: {
				alignItems: "center",
				background: Colors.White,
				display: "flex",
				height: "100vh",
				justifyContent: "center"
			}
		};

		return (
			<div style={styles.container}>
				
			</div>
		);
	}
}

export default Radium(Loading);