import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// API
import { requestLogin, sectors, signup } from '../resources/Auth';

import Loading from '../components/loading';
import LoginHeader from '../components/loginHeader';

var validator = require("email-validator");

/* This is the login */
class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			checkSize: "",
			company: "",
			email: "",
			emailState: 0,
			firstName: "",
			fundraisingStage: "",
			isInvestor: true,
			isLead: false,
			lastName: "",
			linkedIn: "",
			sectors: [],
			selectedSectors: [],
			showSubmit: false,
			lockedRequests: false
		};

		this.editCheckSize = this.editCheckSize.bind(this);
		this.editCompany = this.editCompany.bind(this);
		this.editEmail = this.editEmail.bind(this);
		this.editFirstName = this.editFirstName.bind(this);
		this.editFundraisingStage = this.editFundraisingStage.bind(this);
		this.editIsInvestor = this.editIsInvestor.bind(this);
		this.editIsLead = this.editIsLead.bind(this);
		this.editLastName = this.editLastName.bind(this);
		this.editLinkedIn = this.editLinkedIn.bind(this);
		this.editSectors = this.editSectors.bind(this);
		this.loadSectors = this.loadSectors.bind(this)
		this.signup = this.signup.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentDidMount() {
		this.loadSectors();
		if (this.props.id) {
			window.localStorage.setItem('redirect', JSON.stringify({
				id: this.props.id,
				expiration: new Date((new Date()).getTime() + 5*60000)
			}));
		}
	}

	render() {
		const styles = {
			container: {
				alignItems: "center",
				borderLeft: "7px solid #B1C640",
				borderRight: "7px solid #B1C640",
				display: "flex",
				minHeight: "100vh",
				justifyContent: "center",
				'@media only screen and (max-width: 1300px)': {
					borderLeft: "5px solid #B1C640",
					borderRight: "5px solid #B1C640"
				}
			}
		};

		return (
			<div style={styles.container}>
				{ !this.state.lockedRequests &&
					<LoginHeader
						checkSize={this.state.checkSize}
						company={this.state.company}
						companyErrored={this.state.companyErrored}
						editCheckSize={this.editCheckSize}
						editCompany={this.editCompany}
						editEmail={this.editEmail}
						editFirstName={this.editFirstName}
						editFundraisingStage={this.editFundraisingStage}
						editIsInvestor={this.editIsInvestor}
						editIsLead={this.editIsLead}
						editLastName={this.editLastName}
						editLinkedIn={this.editLinkedIn}
						editSectors={this.editSectors}
						email={this.state.email}
						emailState={this.state.emailState}
						firstName={this.state.firstName}
						fundraisingStage={this.state.fundraisingStage}
						isInvestor={this.state.isInvestor}
						isLead={this.state.isLead}
						lastName={this.state.lastName}
						checkSizeErrored={this.state.checkSizeErrored}
						firstNameErrored={this.state.firstNameErrored}
						fundraisingStageErrored={this.state.fundraisingStageErrored}
						lastNameErrored={this.state.lastNameErrored}
						sectors={this.state.sectors}
						selectedSectors={this.state.selectedSectors}
						selectedSectorsErrored={this.state.selectedSectorsErrored}
						showSubmit={this.state.showSubmit}
						submit={this.submit}
						signup={this.signup}
					/>
				}
				{
					this.state.lockedRequests &&
					<Loading />
				}
			</div>
		);
	}

	async editCompany(entry) {
		await this.setState({company: entry});
	}

	async editEmail(entry) {
		await this.setState({email: entry});

		if (validator.validate(this.state.email)) {
			this.setState({
				showSubmit: true
			});
		} else {
			this.setState({
				showSubmit: false
			});
		}
	}

	async editFirstName(entry) {
		this.setState({firstName: entry});
	}

	async editLastName(entry) {
		this.setState({lastName: entry});
	}

	async editIsInvestor(event) {
		this.setState({isInvestor: event.target.checked});
	}
	
	async editIsLead(event) {
		this.setState({isLead: event.target.checked});
	}

	async editLinkedIn(entry) {
		this.setState({linkedIn: entry});
	}

	async editSectors(event, entries) {
		this.setState({selectedSectors: entries});
	}

	async editCheckSize(event, value) {
		this.setState({checkSize: value.props.value});
	}

	async editFundraisingStage(event, value) {
		this.setState({fundraisingStage: value.props.value});
	}

	async submit() {
		if (this.state.lockedRequests) return;
		await this.setState({lockedRequests: true});
		let success = await requestLogin(this.state.email);

		if (success) {
			this.setState({
				email: "",
				emailState: 1,
				showSubmit: false,
				lockedRequests: false
			});
		} else {
			this.setState({
				emailState: 2,
				showSubmit: true,
				lockedRequests: false
			});
		}
	}

	async loadSectors() {
		let allSectors = await sectors();
		this.setState({
			sectors: allSectors
		});
	}

	async signup() {
		if (this.state.lockedRequests) return;
		await this.setState({lockedRequests: true});
		if (this.state.email === "" || this.state.company === "" || this.state.firstName === "" || this.state.lastName === "" || (false && (this.state.selectedSectors.length < 1 || this.state.checkSize === "" || this.state.fundraisingStage === "" || this.state.linkedIn === ""))) {
			if (this.state.company === "") {
				this.setState({companyErrored: true});
			} else {
				this.setState({companyErrored: false});
			}
			if (this.state.firstName === "") {
				this.setState({firstNameErrored: true});
			} else {
				this.setState({firstNameErrored: false});
			}
			if (this.state.lastName === "") {
				this.setState({lastNameErrored: true});
			} else {
				this.setState({lastNameErrored: false});
			}
			if (this.state.selectedSectors.length < 1) {
				this.setState({selectedSectorsErrored: true});
			} else {
				this.setState({selectedSectorsErrored: false});
			}
			if (this.state.checkSize === "") {
				this.setState({checkSizeErrored: true});
			} else {
				this.setState({checkSizeErrored: false});
			}
			if (this.state.fundraisingStage === "") {
				this.setState({fundraisingStageErrored: true});
			} else {
				this.setState({fundraisingStageErrored: false});
			}
			await this.setState({lockedRequests: false});
			return;
		}

		let success = await signup(
			this.state.email,
			this.state.company,
			this.state.firstName,
			this.state.lastName,
			this.state.selectedSectors.map(x => x.id),
			this.state.checkSize,
			this.state.fundraisingStage,
			this.state.isLead,
			this.state.linkedIn,
			this.state.isInvestor
		);

		if (success) {
			this.setState({
				email: "",
				emailState: 1,
				showSubmit: false,
				lockedRequests: false
			});
		} else {
			this.setState({
				emailState: 2,
				showSubmit: true,
				lockedRequests: false
			});
		}
	}
}

export default Radium(Login);