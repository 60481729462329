import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {StyleRoot} from 'radium';

// Pages
import Main from './pages/Main';
import Admin from './pages/Admin';
import Watch from './pages/Watch';

// Fonts
import './fonts/fonts.css';

/* This is the root */
class App extends Component {
	state = {
		data: {}
	}

	componentDidMount() {
		document.title = "Pear Demo Day";
	}

	render() {
		const theme = createMuiTheme({
			typography: {
				fontFamily: [
					'Museo',
					'sans-serif'
				].join(',')
			}
		});
		return (
			<ThemeProvider theme={theme}>
				<StyleRoot>
					<Router>
						<Switch>
							<Route path="/admin" component={Admin} />
							<Route path="/company/:id" component={Main} />
							<Route path="/watch" component={Watch} />
							<Route path="/" component={Main} />
						</Switch>
					</Router>
				</StyleRoot>
			</ThemeProvider>
		);
	}
}

export default App;