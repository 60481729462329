import React, { Component } from 'react';
import queryString from 'query-string';

// API
import { authorize, login } from '../resources/Auth';

// Screens
import Loading from "./Loading";
import Login from "./Login";
import Home from "./Home";

/* This is the home */
class Main extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: "",
			firstName: "Guest",
			password: "",
			redirect: "none",
			id: this.props.match.path === '/company/:id' ? this.props.match.params["id"] : null
		}

		this.checkLogin = this.checkLogin.bind(this);
	}

	componentDidMount() {
		this.checkLogin();
	}

	render() {
		switch(this.state.redirect) {
			case "home":
				return (
					<Home id={this.state.id} />
				);
			case "login":
				return (
					<Login id={this.state.id} />
				);
			default:
				return (
					<Loading />
				);
		}
	}

	async checkLogin() {
		const query = queryString.parse(this.props.location.search);

		if (query.key) {
			let tryLogin = await login(query.key);
			let redirectId = this.checkRedirectId();
			if (tryLogin) {
				this.setState({redirect: 'home', id: redirectId});
			} else {
				window.location.href = "/";
				// this.setState({redirect: 'bad_key'});
			}
		} else {
			let loggedIn = await authorize();
			window.localStorage.removeItem('redirect');
			if (loggedIn) {
				this.setState({redirect: 'home'});
			} else {
				this.setState({redirect: 'login'});
			}
		}
	}
	
	checkRedirectId() {
		let redirectId = window.localStorage.getItem('redirect');
		if (redirectId) {
			redirectId = JSON.parse(redirectId);
			window.localStorage.removeItem('redirect');
			if (new Date(redirectId["expiration"]) >= (new Date())) {
				return redirectId["id"];
			}
		}
		return null;
	}
}

export default Main;
