// React
import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// Resources
import Colors from '../resources/Colors';

class Companies extends Component {
	constructor(props) {
		super(props);

		this.renderCompanies = this.renderCompanies.bind(this);
	}

	renderCompanies(companies) {
		let output = [];

		for (var i = 0; i !== companies.length; i++) {
			if (i === 0) {
				output.push(
					<Section title="PearX Companies" top={true} />
				);
			}
			if (companies[i]["id"] === "dccf8215-f3a9-49f9-a4d6-2d6e3b943579") {
				// output.push(
				// 	<Intermission
				// 		intermission={this.props.presenting === 'intermission'}
				// 		key="intermission"
				// 	/>
				// );
				output.push(
					<Section title="Pear Portfolio Raising Seed & Series A" />
				);
			}
			output.push(
				<Company
					active={!companies[i]["connected"]}
					connectCompany={this.props.connectCompany}
					logo={companies[i]["logo"]}
					name={companies[i]["name"]}
					description={companies[i]["summary"]}
					id={companies[i]["id"]}
					index={i}
					key={companies[i]["id"]}
					openCompany={this.props.openCompany}
					presenting={this.props.presenting === companies[i]["id"]}
				/>
			);
		}

		return output;
	}

	render() {
		const styles = {
			container: {
				paddingTop: "50px",
				textAlign: "center",
				'@media only screen and (max-width: 1300px)': {
					paddingTop: "30px"
				}
			},
			laterSection: {
				margin: '40px 0 30px 0',
				'@media only screen and (max-width: 1300px)': {
					margin: '40px 0 30px 20px'
				},
				'@media only screen and (max-width: 850px)': {
					margin: '30px 0 10px 0'
				}
			},
			logo: {
				height: "150px"
			},
			sectionHeader: {
				color: Colors.Black,
				fontFamily: 'Financier',
				fontSize: '25px',
				fontWeight: 600,
				margin: '0 0 30px 0',
				position: "relative",
				textAlign: 'left',
				'@media only screen and (max-width: 1300px)': {
					margin: '0 0 30px 20px'
				},
				'@media only screen and (max-width: 850px)': {
					fontSize: '23px',
					margin: '0 0 10px 0',
					textAlign: 'center'
				}
			},
			sectionHeaderUnderline: {
				background: Colors.Green,
				content: "",
				height: "5px",
				left: "-1px",
				position: "relative",
				top: "3px",
				width: "100px",
				'@media only screen and (max-width: 850px)': {
					left: '50%',
					transform: 'translateX(-50%)'
				}
			},
			sectionHeaderUnderlineBlue: {
				background: Colors.Blue,
				content: "",
				height: "5px",
				left: "-1px",
				position: "relative",
				top: "3px",
				width: "100px",
				'@media only screen and (max-width: 850px)': {
					left: '50%',
					transform: 'translateX(-50%)'
				}
			}
		};

		let companies = this.props.data || [];

		return (
			<div style={styles.container}>
				{this.renderCompanies(companies)}
			</div>
		);
	}
}

class Section extends Component {
	render() {
		const styles = {
			sectionHeader: {
				color: Colors.Black,
				fontFamily: 'Financier',
				fontSize: '25px',
				fontWeight: 600,
				margin: '30px 0 30px 0',
				marginTop: this.props.top ? '30px' : '60px',
				position: "relative",
				textAlign: 'left',
				'@media only screen and (max-width: 1300px)': {
					margin: '0 0 30px 20px'
				},
				'@media only screen and (max-width: 850px)': {
					fontSize: '23px',
					margin: '0 0 10px 0',
					textAlign: 'center'
				}
			},
			sectionHeaderUnderline: {
				background: Colors.Green,
				content: "",
				height: "5px",
				left: "-1px",
				position: "relative",
				top: "3px",
				width: "100px",
				'@media only screen and (max-width: 850px)': {
					left: '50%',
					transform: 'translateX(-50%)'
				}
			},
			sectionHeaderUnderlineBlue: {
				background: Colors.Blue,
				content: "",
				height: "5px",
				left: "-1px",
				position: "relative",
				top: "3px",
				width: "100px",
				'@media only screen and (max-width: 850px)': {
					left: '50%',
					transform: 'translateX(-50%)'
				}
			}
		}
		
		return (
			<div style={styles.sectionHeader}>
				{ this.props.title }
				<div style={styles.sectionHeaderUnderline} />
			</div>
		);
	}
}

class Company extends Component {
	constructor(props) {
		super(props);

		this.connectCompany = this.connectCompany.bind(this);
		this.openCompany = this.openCompany.bind(this);
	}

	render() {
		const styles = {
			briefing: {
				marginLeft: "20px",
				width: '100%',
				'@media only screen and (max-width: 850px)': {
					marginLeft: 0
				}
			},
			button: {
				background: Colors.Green,
				borderRadius: "3px",
				color: "#FFFFFF",
				cursor: "pointer",
				fontFamily: 'CeraPro',
				fontSize: '15px',
				fontWeight: 500,
				padding: "7px 0px",
				width: '105px',
				':hover': {
					background: Colors.GreenHover
				},
				'@media only screen and (max-width: 1300px)': {
					fontSize: '14px'
				},
				'@media only screen and (max-width: 850px)': {
					display: 'none'
				}
			},
			buttonMobile: {
				background: Colors.Green,
				borderRadius: "3px",
				color: "#FFFFFF",
				cursor: "pointer",
				display: 'none',
				fontFamily: 'CeraPro',
				fontSize: '14px',
				fontWeight: 500,
				padding: "8px 0px",
				width: '100px',
				':hover': {
					background: Colors.GreenHover
				},
				'@media only screen and (max-width: 850px)': {
					display: 'block'
				}
			},
			buttonLearn: {
				background: Colors.Blue,
				borderRadius: "3px",
				color: "#FFFFFF",
				cursor: "pointer",
				fontFamily: 'CeraPro',
				fontSize: '15px',
				fontWeight: 500,
				marginRight: '10px',
				padding: "8px 0px",
				whiteSpace: 'nowrap',
				width: '105px',
				':hover': {
					background: Colors.BlueHover
				},
				'@media only screen and (max-width: 1300px)': {
					fontSize: '14px',
					marginBottom: '10px'
				},
				'@media only screen and (max-width: 850px)': {
					display: 'none'
				}
			},
			buttonLearnMobile: {
				background: Colors.Blue,
				borderRadius: "3px",
				color: "#FFFFFF",
				cursor: "pointer",
				display: 'none',
				fontFamily: 'CeraPro',
				fontSize: '14px',
				fontWeight: 500,
				padding: "8px 0px",
				marginRight: '10px',
				width: '100px',
				':hover': {
					background: Colors.BlueHover
				},
				'@media only screen and (max-width: 850px)': {
					display: 'block'
				}
			},
			buttons: {
				display: 'flex',
				'@media only screen and (max-width: 1300px)': {
					display: 'block'
				}
			},
			buttonsMobile: {
				display: 'flex',
				marginTop: '10px'
			},
			container: {
				alignItems: "center",
				cursor: "pointer",
				display: "flex",
				flex: 1,
				minHeight: '75px',
				justifyContent: "space-between",
				padding: "7px 0px"
			},
			content: {
				alignItems: "center",
				borderRadius: "3px",
				cursor: "pointer",
				display: "flex",
				flex: 1,
				minHeight: '75px',
				justifyContent: "space-between",
				padding: "10px 30px 10px 30px",
				':hover': {
					background: Colors.LightGray
				},
				'@media only screen and (max-width: 850px)': {
					padding: "15px 20px 15px 20px"
				}
			},
			description: {
				flex: 2,
				fontFamily: 'CeraPro',
				fontSize: '17px',
				fontWeight: 300,
				lineHeight: '22px',
				margin: "2px 15px 0 0",
				maxWidth: '900px',
				textAlign: "left",
				'@media only screen and (max-width: 1300px)': {
					fontSize: '15px'
				},
				'@media only screen and (max-width: 850px)': {
					margin: "15px 15px 0 0"
				}
			},
			inactiveButton: {
				background: "#DFDFDF",
				borderRadius: "3px",
				color: "#FFFFFF",
				fontFamily: 'CeraPro',
				fontSize: '15px',
				fontWeight: 500,
				padding: "8px 0px",
				width: '105px',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '14px'
				},
				'@media only screen and (max-width: 850px)': {
					display: 'none'
				}
			},
			inactiveButtonMobile: {
				background: "#DFDFDF",
				borderRadius: "3px",
				color: "#FFFFFF",
				display: 'none',
				fontFamily: 'CeraPro',
				fontSize: '14px',
				fontWeight: 500,
				padding: "8px 0px",
				width: '100px',
				'@media only screen and (max-width: 850px)': {
					display: 'block'
				}
			},
			left: {
				alignItems: "center",
				display: "flex",
				// height: '60px',
				width: "100%"
			},
			logo: {
				borderRadius: !this.props.logo ? "30px" : 0,
				height: "60px",
				maxWidth: "100px"
				// width: "60px"
			},
			logoArea: {
				backgroundImage: 'url(' + this.props.logo +')',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				height: "60px",
				textAlign: "center",
				minWidth: "100px",
				width: "100px",
				'@media only screen and (max-width: 850px)': {
					display: 'none'
				}
			},
			mobileWrapper: {
				// display: 'flex',
				// justifyContent: 'space-between',
				display: 'block',
				width: '100%'
			},
			name: {
				alignItems: 'center',
				display: 'flex',
				flex: 1,
				fontFamily: 'Financier',
				fontSize: '23px',
				fontWeight: 500,
				justifyContent: 'flex-start',
				margin: 0,
				textAlign: "left"
			},
			presenting: {
				border: '1px solid #FA2E27',
				borderRadius: '3px',
				color: '#FA2E27',
				display: this.props.presenting ? 'block' : 'none',
				fontFamily: 'Financier',
				fontSize: '15px',
				fontWeight: 500,
				marginLeft: '10px',
				padding: '3px 7px'
			}
		};
		// <img alt={this.props.name} src={this.props.logo} style={styles.logo} />

		return (
			<div style={styles.container}>
				<div style={styles.content} key={this.props.id} onClick={this.openCompany}>
					<div style={styles.left}>
						<div style={styles.logoArea} />
						<div style={styles.briefing}>
							<div style={styles.mobileWrapper}>
								<h1 style={styles.name}>{this.props.name}<div style={styles.presenting}>Presenting Now</div></h1>
								<div style={styles.buttonsMobile}>
									<div key="mobileLearn" style={styles.buttonLearnMobile}>
										Learn More
									</div>
									<div onClick={this.connectCompany} name="buttonMobile" key="mobile" style={this.props.active ? styles.buttonMobile : styles.inactiveButtonMobile}>
										{this.props.active ? "Connect" : "Connected"}
									</div>
								</div>
							</div>
							<h2 style={styles.description}>{this.props.description}</h2>
						</div>
					</div>
					<div style={styles.buttons}>
						<div key="webLearn" style={styles.buttonLearn}>
							Learn More
						</div>
						<div onClick={this.connectCompany} name="buttonWeb" key="web" style={this.props.active ? styles.button : styles.inactiveButton}>
							{this.props.active ? "Connect" : "Connected"}
						</div>
					</div>
				</div>
			</div>
		);
	}

	connectCompany() {
		if (this.props.active) {
			this.props.connectCompany(this.props.id, this.props.name);
		}
	}

	openCompany(e) {
		if (e.target.getAttribute("name") && e.target.getAttribute("name").includes("button")) return;
		this.props.openCompany(this.props.id);
	}
}

class Intermission extends Component {
	render() {
		const styles = {
			container: {
				alignItems: 'center',
				// border: this.props.intermission ? '1px solid #FA2E27' : 'none',
				borderColor: this.props.intermission ? Colors.Green : Colors.MediumGray,
				borderRadius: this.props.intermission ? '5px' : 0,
				borderStyle: 'solid',
				borderWidth: this.props.intermission ? '1px' : '1px 0 1px 0',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				margin: '20px auto',
				maxWidth: '400px',
				padding: '15px 10px 15px 10px',
				width: 'calc(100% - 20px)'
			},
			counter: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			},
			intermission: {
				color: this.props.intermission ? Colors.Green : Colors.MediumGray,
				fontFamily: 'Financier',
				fontSize: '30px',
				fontWeight: 700,
				textTransform: 'uppercase'
			},
			timeItem: {
                alignItems: 'flex-start',
                display: 'flex',
                padding: '0 10px'
            },
            timerLabel: {
                color: Colors.Gray,
                fontFamily: 'Financier',
				fontSize: '20px',
                fontWeight: 500,
				lineHeight: '25px',
				margin: '0 0 15px 0',
				textTransform: 'uppercase'
            },
            timeValue: {
                color: Colors.Green,
                fontFamily: 'Financier',
				fontSize: '30px',
				fontWeight: 600,
                lineHeight: '25px',
                padding: 0
            }
		};

		return (
			<div style={styles.container}>
				<h1 style={styles.intermission}>{this.props.intermission ? "We are in " : ""}Intermission</h1>
				{ this.props.intermission &&
					<div style={styles.counter}>
						<h2 style={styles.timerLabel}>We'll be back soon!</h2>
					</div>
				}
			</div>
		);
	}
}

Company = Radium(Company);
Section = Radium(Section);
export default Radium(Companies);