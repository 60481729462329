import React, { Component } from 'react';

// Libraries
import dayjs from 'dayjs';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Radium from 'radium';
import { Line } from 'react-chartjs-2';

// Resources
import Colors from '../resources/Colors';

// API
import { connections, isLive, startTime, saveCompanyWith, sendBlast, setStatus } from '../resources/Admin';
import { getCompanies } from '../resources/Companies';

/* This is the login */
class Admin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			companies: [],
			companyEditingModified: false,
			companyTimeData: {},
			data: [],
			editingCompany: 0,
			editingCompanySummary: "",
			editingCompanyYouTube: "",
			editingCompanyProblem: "",
			editingCompanyURL: "",
			editingCompanyLogoURL: "",
			editingCompanyOrdering: "",
			editingCompanySolution: "",
			editingCompanyMarket: "",
			editingCompanyTraction: "",
			editingCompanyHelp: "",
			live: false,
			open: false,
			presenting: null,
			tab: 2,
			timeBinSize: 5, // Minutes
			timeData: [],
			zoomLink: null
		};

		this.changeEditingCompany = this.changeEditingCompany.bind(this);
		this.changeLive = this.changeLive.bind(this);
		this.changeTab = this.changeTab.bind(this);
		this.companyDataFrom = this.companyDataFrom.bind(this);
		this.editCompanySummary = this.editCompanySummary.bind(this);
		this.editCompanyYouTube = this.editCompanyYouTube.bind(this);
		this.editCompanyProblem = this.editCompanyProblem.bind(this);
		this.editCompanyURL = this.editCompanyURL.bind(this);
		this.editCompanyLogoURL = this.editCompanyLogoURL.bind(this);
		this.editCompanyOrdering = this.editCompanyOrdering.bind(this);
		this.editCompanySolution = this.editCompanySolution.bind(this);
		this.editCompanyMarket = this.editCompanyMarket.bind(this);
		this.editCompanyTraction = this.editCompanyTraction.bind(this);
		this.editCompanyHelp = this.editCompanyHelp.bind(this);
		this.editZoomLink = this.editZoomLink.bind(this);
		this.graphTime = this.graphTime.bind(this);
		this.loadData = this.loadData.bind(this);
		this.renderCompanyActivationTiles = this.renderCompanyActivationTiles.bind(this);
		this.renderCompaniesSelect = this.renderCompaniesSelect.bind(this);
		this.renderConnections = this.renderConnections.bind(this);
		this.renderEditableCompaniesSelect = this.renderEditableCompaniesSelect.bind(this);
		this.saveCompany = this.saveCompany.bind(this);
		this.selectCompany = this.selectCompany.bind(this);
		this.setCompanyModified = this.setCompanyModified.bind(this);
		this.setCurrentCompanyWith = this.setCurrentCompanyWith.bind(this);
		this.setZoomLink = this.setZoomLink.bind(this);
		this.startIntermission = this.startIntermission.bind(this);
	}

	componentDidMount() {
		this.loadData();
	}

	styles = {
		actions: {
			display: 'flex',
			marginTop: '30px'
		},
		body: {
			flex: 1
		},
		button: {
			alignItems: 'center',
			background: Colors.Green,
			borderRadius: '4px',
			color: Colors.White,
			cursor: 'pointer',
			display: 'flex',
			fontFamily: 'CeraPro',
			fontSize: '15px',
			fontWeight: 500,
			height: '50px',
			justifyContent: 'center',
			marginRight: '10px',
			width: '200px'
		},
		companyData: {
			margin: '30px 0 0 0'
		},
		companyDataArea: {
			display: 'flex'
		},
		container: {
			alignItems: "center",
			borderLeft: "7px solid #B1C640",
			borderRight: "7px solid #B1C640",
			display: "flex",
			flexDirection: 'column',
			// height: "100vh",
			justifyContent: "center",
			'@media only screen and (max-width: 1300px)': {
				borderLeft: "5px solid #B1C640",
				borderRight: "5px solid #B1C640"
			}
		},
		content: {
			alignItems: "flex-start",
			display: "block",
			flexDirection: 'column',
			height: '100vh',
			justifyContent: "center",
			padding: '0 100px',
			width: 'calc(100% - 200px)'
		},
		editor: {
			columnGap: '20px',
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',
			gridTemplateRows: 'auto',
			gridTemplateAreas: `
				"selector selector"
				"textLine textLine"
			`,
			paddingTop: '20px',
			rowGap: '20px'
		},
		editorSelector: {
			gridArea: 'selector',
			margin: '10px 0 0 0',
			// width: '250px'
		},
		editorText: {
			// marginTop: '30px'
		},
		editorTextLine: {
			gridArea: 'textLine'
			// marginTop: '30px'
		},
		graph: {
			height: '400px',
			width: '700px'
		},
		graphs: {
			margin: '30px 0 0 0',
			paddingBottom: '100px'
		},
		row: {
			display: 'flex'
		},
		saveArea: {
			display: 'flex',
			justifyContent: 'flex-end',
			padding: '20px 0 0 0'
		},
		sideData: {
			margin: '0 30px'
		},
		table: {
			border: '1px solid ' + Colors.MediumGray,
			borderRadius: '4px',
			height: '300px',
			overflow: 'scroll',
			padding: '10px'
		},
		td: {
			fontFamily: 'CeraPro',
			fontSize: '17px',
			fontWeight: 500,
			margin: 0,
			padding: '3px 20px 3px 0',
			width: '100px'
		},
		tdThin: {
			fontFamily: 'CeraPro',
			fontSize: '17px',
			fontWeight: 300,
			margin: 0,
			paddingRight: '3px 20px 3px 0'
		},
		title: {
			fontFamily: 'Financier',
			fontSize: '25px',
			fontWeight: 600
		}
	};

	render() {
		return (
			<div style={this.styles.container}>
				{ this.state.open &&
					<div style={this.styles.content}>
						{/* <Header
							name={localStorage.getItem("name") + " (Admin)"}
							message="You can administrate things from here."
						/> */}
						<Paper>
							<Tabs
								value={this.state.tab}
								indicatorColor="primary"
								textColor="primary"
								onChange={this.changeTab}
								aria-label="disabled tabs example"
							>
								<Tab label="Control Panel" />
								<Tab label="Stats" />
								<Tab label="Company Editor" />
							</Tabs>
						</Paper>
						{ this.state.tab === 0 &&
							<Box>
								<div style={this.styles.body}>
									<div style={this.styles.actions}>
										<div onClick={this.changeLive} style={[this.styles.button, {background: this.state.live ? Colors.Green : Colors.MediumGray}]}>{this.state.live ? 'Deactivate / Make private' : 'Activate / Enable public access'}</div>
										{/* <div onClick={this.blast} style={this.styles.button}>{'Send Blast'}</div> */}
									</div>
									<div style={{margin: '30px 0'}}>
										<div>Start time (change on Retool): {this.state.startTime}</div>
										{/* <div onClick={this.blast} style={this.styles.button}>{'Send Blast'}</div> */}
									</div>
									<div style={{margin: '30px 0'}}>
										<TextField
											label="Zoom Link"
											onChange={this.editZoomLink}
											style={this.styles.editorTextLine}
											value={this.state.zoomLink}
											variant="outlined"
										/>
										<div onClick={this.setZoomLink} style={[this.styles.button, {background: this.state.zoomLink ? Colors.Green : Colors.MediumGray}]}>{!this.state.zoomLink ? 'Deactivate Zoom Link' : 'Open Zoom'}</div>
									</div>
									{
										this.renderCompanyActivationTiles()
									}
								</div>
							</Box>
						}
						{ this.state.tab === 1 &&
							<Box>
								<div style={this.styles.graphs}>
									<div style={this.styles.graph}>
										<Line data={this.state.timeData} height={350} width={700} />
									</div>
									<div style={this.styles.companyData}>
										<select onChange={this.selectCompany}>
											{this.renderCompaniesSelect()}
										</select>
										{ this.state.companyTimeData["graph"] &&
											<div style={this.styles.companyDataArea}>
												<div style={this.styles.graph}>
													<Line data={this.state.companyTimeData["graph"]} height={400} width={700} />
												</div>
												<div style={this.styles.sideData}>
													<h1 style={this.styles.title}>Total: {this.state.companyTimeData["total"]}</h1>
													<div style={this.styles.table}>
														{this.renderConnections()}
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</Box>
						}
						{ this.state.tab === 2 &&
							<Box>
								<div style={this.styles.saveArea}>
									<Button onClick={this.saveCompany} variant="contained" color="primary" disabled={!this.state.companyEditingModified}>
										Save Company
									</Button>
								</div>
								<div style={this.styles.editor}>
									<FormControl style={this.styles.editorSelector} variant="outlined">
										<InputLabel id="demo-simple-select-outlined-label">Company</InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											value={this.state.editingCompany}
											onChange={this.changeEditingCompany}
											label="Company"
										>
										{
											this.renderEditableCompaniesSelect()
										}
										</Select>
									</FormControl>
									<TextField
										// id="summary"
										label="One-liner / Short Description"
										onChange={this.editCompanySummary}
										// defaultValue="Please fill me in!"
										style={this.styles.editorTextLine}
										value={this.state.editingCompanySummary}
										variant="outlined"
									/>
									<TextField
										// id="summary"
										label="YouTube ID (just the ID part of youtube.com/ID)"
										onChange={this.editCompanyYouTube}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyYouTube}
										variant="outlined"
									/>
									<TextField
										// id="problem"
										label="Paragraph / Problem"
										multiline
										onChange={this.editCompanyProblem}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyProblem}
										variant="outlined"
									/>

									<TextField
									// id="URL"
										label="URL"
										multiline
										onChange={this.editCompanyURL}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyURL}
										variant="outlined"
									/>

									<TextField
									// id="logo URL"
										label="Logo URL in S3"
										multiline
										onChange={this.editCompanyLogoURL}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyLogoURL}
										variant="outlined"
									/>

									<TextField
									// id="Ordering"
										label="Ordering"
										multiline
										onChange={this.editCompanyOrdering}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyOrdering}
										variant="outlined"
									/>

									{/* Following fields are deprecated / not used on the site */}
									{/* <TextField
										// id="outlined-multiline-static"
										label="Solution (Deprecated)"
										multiline
										onChange={this.editCompanySolution}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanySolution}
										variant="outlined"
									/>
									<TextField
										// id="outlined-multiline-static"
										label="Market Opportunity (Deprecated)"
										multiline
										onChange={this.editCompanyMarket}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyMarket}
										variant="outlined"
									/>
									<TextField
										// id="outlined-multiline-static"
										label="Traction (Deprecated)"
										multiline
										onChange={this.editCompanyTraction}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyTraction}
										variant="outlined"
									/>
									<TextField
										// id="outlined-multiline-static"
										label="How Can You Help Us? (Deprecated)"
										multiline
										onChange={this.editCompanyHelp}
										rows={4}
										// defaultValue="Please fill me in!"
										style={this.styles.editorText}
										value={this.state.editingCompanyHelp}
										variant="outlined"
									/> */}
								</div>
							</Box>
						}
					</div>
				}
			</div>
		);
	}

	renderCompanyActivationTiles() {
		let { companies } = this.state;
		
		return (<div style={{
			display: 'flex',
			flexFlow: 'wrap',
			width: '100%'
		}}>
			{
				companies.map(x =>
					<ActivationTile
						id={x.id}
						key={x.id} 
						logo={x.logo}
						presenting={this.state.presenting}
						setCurrentCompanyWith={this.setCurrentCompanyWith}
					/>
				)
			}
			<button onClick={this.startIntermission}>Intermission</button>
		</div>);
	}

	changeTab(event, value) {
		this.setState({ tab: value});
	}

	changeEditingCompany(event, value) {
		this.setState({ companyEditingModified: false, editingCompany: value.props.value});
		this.companyDataFrom(value.props.value);
	}

	companyDataFrom(id) {
		let { companies } = this.state;
		let company = companies.filter(x => x.id === id);
		this.setState({
			editingCompanyProblem: company[0]["problem"] || "",

			editingCompanySolution: company[0]["solution"] || "",
			editingCompanyURL: company[0]["url"] || "",
			editingCompanyLogoURL: company[0]["logo"] || "",
			editingCompanyOrdering: company[0]["ordering"] || "",
			editingCompanyMarket: company[0]["market"] || "",
			editingCompanyTraction: company[0]["traction"] || "",
			editingCompanyHelp: company[0]["help"] || "",
			editingCompanySummary: company[0]["summary"] || ""
		});
		return company[0];
	}

	editCompanySummary(event) {
		this.setState({ editingCompanySummary: event.target.value });
		this.setCompanyModified();
	}

	editCompanyYouTube(event) {
		this.setState({ editingCompanyYouTube: event.target.value });
		this.setCompanyModified();
	}
	
	editCompanyProblem(event) {
		this.setState({ editingCompanyProblem: event.target.value });
		this.setCompanyModified();
	}

	editCompanyURL(event) {
		this.setState({ editingCompanyURL: event.target.value });
		this.setCompanyModified();
	}

	editCompanyLogoURL(event) {
		this.setState({ editingCompanyLogoURL: event.target.value });
		this.setCompanyModified();
	}

	editCompanyOrdering(event) {
		this.setState({ editingCompanyOrdering: event.target.value });
		this.setCompanyModified();
	}

	editCompanySolution(event) {
		this.setState({ editingCompanySolution: event.target.value });
		this.setCompanyModified();
	}

	editCompanyMarket(event) {
		this.setState({ editingCompanyMarket: event.target.value });
		this.setCompanyModified();
	}

	editCompanyTraction(event) {
		this.setState({ editingCompanyTraction: event.target.value });
		this.setCompanyModified();
	}

	editCompanyHelp(event) {
		this.setState({ editingCompanyHelp: event.target.value });
		this.setCompanyModified();
	}

	setCompanyModified() {
		this.setState({ companyEditingModified: true });
	}

	async saveCompany() {
		if (!this.state.companyEditingModified) return;
		await saveCompanyWith(this.state.editingCompany, {
			summary: this.state.editingCompanySummary,
			problem: this.state.editingCompanyProblem,
			solution: this.state.editingCompanySolution,
			url: this.state.editingCompanyURL,
			logo: this.state.editingCompanyLogoURL,
			ordering: parseInt(this.state.editingCompanyOrdering),
			market: this.state.editingCompanyMarket,
			traction: this.state.editingCompanyTraction,
			help: this.state.editingCompanyHelp,
			video: this.state.editingCompanyYouTube
		});
		await this.loadData(this.state.editingCompany);
		this.setState({ companyEditingModified: false });
	}

	renderCompaniesSelect() {
		let output = [];
		for (var i = 0; i !== this.state.companies.length; i++) {
			let company = this.state.companies[i];
			output.push(
				<option key={company.id} value={company.name}>{company.name}</option>
			);
		}
		return output;
	}

	renderEditableCompaniesSelect() {
		let output = [];
		for (var i = 0; i !== this.state.companies.length; i++) {
			let company = this.state.companies[i];
			output.push(
				<MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
			);
		}
		return output;
	}

	renderConnections() {
		let output = [];
		for (var i = 0; i !== this.state.companyTimeData["connections"].length; i++) {
			let connection = this.state.companyTimeData["connections"][i];
			console.log(connection);
			output.push(
				<div key={connection.investor + connection.firm} style={this.styles.row}>
					<p style={this.styles.td}>{connection.investor}</p>
					<p style={this.styles.tdThin}>{connection.firm}</p>
				</div>
			);
		}
		return output;
	}

	async setCurrentCompanyWith(id) {
		await setStatus('presenting', id);
		this.setState({
			presenting: id
		});
	}

	async editZoomLink(event) {
		this.setState({ zoomLink: event.target.value });
	}

	async setZoomLink() {
		await setStatus('zoom', this.state.zoomLink);
	}

	async startIntermission() {
		// await setStatus('intermission', (new Date()).getTime() + 10*1000);
		await setStatus('presenting', 'intermission');
		this.setState({
			presenting: 'intermission'
		});
	}

	async blast() {
		let key = window.prompt("Enter admin key");
		await sendBlast(key);
	}

	async changeLive() {
		// let success = await toggleLive(!this.state.live);
		let success = await setStatus('status', !this.state.live ? 'active' : 'inactive');
		if (success) {
			this.setState({live: !this.state.live});
		}
	}

	async loadData(id) {
		const live = await isLive();
		if (!live.success) {
			window.location.href = "/";
			return;
		}

		const demoDayStartTimeEpoch = await startTime();

		// Parse epoch time to nice readable format like October 5th, 9am 2022
		var demoDayStartTime = dayjs(parseInt(demoDayStartTimeEpoch.time)).format(' h:mm a on MMMM D, YYYY');
		
		// Data for analytics
		let data = await connections();
		data = data.map(function(x) {
			x.time = new Date(parseInt(x.time));
			return x;
		});

		// Companies for editing
		let companies = await getCompanies();
		this.setState({
			companies: companies.data,
			data: data,
			editingCompany: id || companies.data[0]["id"],
			// currentCompany: companies.currentCompany,
			presenting: companies.presenting,
			live: live.live,
			open: true,
			zoomLink: companies.zoomLink,
			startTime: demoDayStartTime,
		});

		



		this.companyDataFrom(id || companies.data[0]["id"]);
		this.graphTime();
		this.graphFilteredTime({
			name: 'company',
			value: 'Company'
		});
		console.log(companies.data[0]["name"]);
		this.selectCompany({target: {value: companies.data[0]["name"]}});
	}

	async graphTime() {
		let data = this.state.data;
		let rounding = 1000 * 60 * this.state.timeBinSize;
		data = data.map(function(x) {
			x.time = new Date(Math.ceil(x.time.getTime()/rounding)*rounding);
			return x;
		});
		data = data.reduce(function(accumulator, current) {
			accumulator[current.time] = accumulator[current.time] ? accumulator[current.time] + 1 : 1;
			return accumulator;
		}, {});

		let allData = Object.keys(data).map(x => data[x]);

		this.setState({timeData: {
			labels: Object.keys(data).map(x => dayjs(x).format('DD/MM HH:mm')),
			datasets: [
				{
					borderColor: Colors.Green,
					borderWidth: 2,
					data: allData,
					fill: false,
					label: "All",
					lineTension: 0.3,
					pointRadius: 0
				}
			]
		}});
	}

	async graphFilteredTime(filter) {
		let data = this.state.data;
		let rounding = 1000 * 60 * this.state.timeBinSize;
		let graphData1 = data.map(function(x) {
			x.time = new Date(Math.ceil(x.time.getTime()/rounding)*rounding);
			return x;
		});
		// data = data.filter(x => x[filter.name] === filter.value);
		let graphData2 = graphData1.reduce(function(accumulator, current) {
			if (current[filter.name] === filter.value) {
				accumulator[current.time] = accumulator[current.time] ? accumulator[current.time] + 1 : 1;
			} else if (!accumulator[current.time]) {
				accumulator[current.time] = 0;
			}
			return accumulator;
		}, {});

		let graphData3 = Object.keys(graphData2).map(x => graphData2[x]);

		this.setState({companyTimeData: {
			connections: data.filter(x => x[filter.name] === filter.value),
			graph: {
				labels: Object.keys(graphData2).map(x => dayjs(x).format('DD/MM HH:mm')),
				datasets: [
					{
						borderColor: Colors.Green,
						borderWidth: 2,
						data: graphData3,
						fill: false,
						label: filter.value,
						lineTension: 0.3,
						pointRadius: 0
					}
				]
			},
			total: 	graphData3.reduce(function(accumulator, current) {
						return accumulator + current;
					}, 0)
		}});
	}

	async selectCompany(e) {
		this.setState({selectedCompany: e.target.value});
		this.graphFilteredTime({
			name: 'company',
			value: e.target.value
		});
	}
}

class ActivationTile extends Component {
	constructor(props) {
		super(props);

		this.setCurrentCompanyWith = this.setCurrentCompanyWith.bind(this);
	}
	
	async setCurrentCompanyWith() {
		if (this.props.presenting === this.props.id) {
			await this.props.setCurrentCompanyWith(null);
			return;
		}
		await this.props.setCurrentCompanyWith(this.props.id);
	}

	render() {
		const styles = {
			activationTile: {
				cursor: 'pointer',
				margin: '10px 10px',
				opacity: this.props.presenting === this.props.id ? 1 : 0.2,
				':hover': {
					opacity: 1
				}
			}
		}
		return (
			<div onClick={this.setCurrentCompanyWith} style={styles.activationTile}>
				<div style={{
					backgroundImage: 'url(' + this.props.logo + ')',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					height: '75px',
					width: '100px'
				}} />
			</div>
		);
	}
}

ActivationTile = Radium(ActivationTile);
export default Radium(Admin);