// React
import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Switch from '@material-ui/core/Switch';

// Libraries
import Radium from 'radium';

// Images
import logo from "../images/logo.png";

// Resources
import Colors from '../resources/Colors';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PearTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: Colors.Green
		},
		'& .MuiOutlinedInput-root': {
			// '& fieldset': {
			//   borderColor: Colors.Green,
			// },
			'&:hover fieldset': {
			  borderColor: Colors.Green,
			},
			'&.Mui-focused fieldset': {
			  borderColor: Colors.Green,
			},
		},
	}
})(TextField);

const PearFormControl = withStyles({
	root: {
		'& label.Mui-focused': {
			color: Colors.Green
		},
		'& .MuiOutlinedInput-root': {
			textAlign: 'left',
			// '& fieldset': {
			//   borderColor: Colors.Green,
			// },
			'&:hover fieldset': {
			  borderColor: Colors.Green,
			},
			'&.Mui-focused fieldset': {
			  borderColor: Colors.Green,
			},
		},
	}
})(FormControl);

const PearSwitch = withStyles({
	switchBase: {
	  color: Colors.MediumGray,
	  '&$checked': {
		color: Colors.Green,
	  },
	  '&$checked + $track': {
		backgroundColor: Colors.Green,
	  },
	},
	checked: {},
	track: {},
  })(Switch);

const PearCheckbox = withStyles({
	root: {
	  color: Colors.MediumGray,
	  '&$checked': {
		color: Colors.Green,
	  },
	},
	checked: {},
  })((props) => <Checkbox color="default" {...props} />);

class LoginHeader extends Component {
	constructor(props) {
		super(props);

		this.checkSubmit = this.checkSubmit.bind(this);
		this.editCompany = this.editCompany.bind(this);
		this.editEmail = this.editEmail.bind(this);
		this.editFirstName = this.editFirstName.bind(this);
		this.editLastName = this.editLastName.bind(this);
		this.editLinkedIn = this.editLinkedIn.bind(this);
		this.signup = this.signup.bind(this);
		this.toLastName = this.toLastName.bind(this);
		this.toCompany = this.toCompany.bind(this);
		this.toLinkedIn = this.toLinkedIn.bind(this);
	}

	render() {
		const styles = {
			container: {
				maxWidth: '90%',
				paddingBottom: this.state.showSubmit ? "53px" : "100px",
				paddingLeft: '20px',
				paddingRight: '20px',
				paddingTop: '50px',
				textAlign: "center",
				width: (this.props.emailState === 2 || this.props.emailState === 1) ? '350px' : 'auto'
			},
			button: {
				background: Colors.Green,
				borderRadius: "4px",
				color: Colors.White,
				cursor: "pointer",
				fontFamily: 'CeraPro',
				fontSize: '14px',
				fontWeight: 500,
				margin: "10px auto 0 auto",
				padding: "11px 0 10px 0",
				// width: "200px",
				width: "100%",
				':hover': {
					background: Colors.GreenHover
				}
			},
			email: {
				border: "1px solid " + Colors.MediumGray,
				borderRadius: "4px",
				fontFamily: 'CeraPro',
				fontSize: '14px',
				fontWeight: 500,
				height: "35px",
				marginTop: "25px",
				outline: "none",
				textAlign: "center",
				// width: "200px"
				width: '100%'
			},
			infoSection: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column'
			},
			inputItem: {
				margin: '15px 0 0 0',
				width: '100%'
			},
			investorFields: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				width: '100%'
			},
			logo: {
				height: "100px",
				'@media only screen and (max-width: 1300px)': {
					height: "80px"
				}
			},
			subtext: {
				color: Colors.Gray,
				fontFamily: 'CeraPro',
				fontSize: '17px',
				fontWeight: 500,
				margin: '10px 0 0 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '15px'
				}
			},
			switchArea: {
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'space-between',
				padding: '10px 3px 5px 15px',
				width: 'calc(100% - 15px)'
			},
			switchLabel: {
				color: Colors.Gray,
				fontFamily: 'CeraPro',
				fontSize: '17px',
				fontWeight: 500,
				maxWidth: '67%',
				textAlign: 'left'
				// padding: '0 0 0 15px'
			},
			textEntry: {
				border: "1px solid " + Colors.MediumGray,
				borderRadius: "4px",
				fontFamily: 'CeraPro',
				fontSize: '14px',
				fontWeight: 500,
				height: "27px",
				marginTop: "10px",
				outline: "none",
				textAlign: "center",
				width: "200px"
			},
			welcome: {
				fontFamily: 'Financier',
				fontSize: '30px',
				fontWeight: 500,
				margin: '20px 0 0 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '25px'
				}
			}
		};

		let title = "Welcome to Pear Demo Day!";
		let subtitle = "Let's start by getting you signed in – no password required";
		switch(this.props.emailState) {
			case 1:
				title = "You're in!";
				subtitle = "Check your email for a login link. If you don't see it, check your spam folder!";
				break;
			case 2:
				title = "Almost there!";
				subtitle = "We just need a little more information...";
				break;
			default:
				break;
		}

		return (
			<div style={styles.container}>
				<img alt="Pear Logo" src={logo} style={styles.logo} />
				<h1 style={styles.welcome}>{title}</h1>
				<h2 style={styles.subtext}>{subtitle}</h2>
				{
					this.props.emailState === 0 &&
					<input onChange={this.editEmail} onKeyDown={this.checkSubmit} style={styles.email} placeholder="Enter your email" type="email" value={this.props.email} />
				}
				{
					this.props.emailState === 1 &&
					<input onChange={this.editEmail} onKeyDown={this.checkSubmit} style={styles.email} placeholder="Used the wrong email? Enter your email" type="email" value={this.props.email} />
				}
				{
					this.props.emailState === 2 &&
					<div style={styles.infoSection}>
						<PearTextField
							error={this.props.firstNameErrored}
							label="First Name"
							onChange={this.editFirstName}
							onKeyDown={this.toLastName}
							id="filled-size-small"
							variant="outlined"
							size="small"
							style={{ margin: '25px 0 0 0', width: '100%' }}
							value={this.props.firstName}
						/>
						<PearTextField
							error={this.props.lastNameErrored}
							label="Last Name"
							onChange={this.editLastName}
							onKeyDown={this.toCompany}
							id="filled-size-small"
							ref="lastName"
							variant="outlined"
							size="small"
							style={styles.inputItem}
							value={this.props.lastName}
						/>
						<PearTextField
							error={this.props.companyErrored}
							label="Company"
							onChange={this.editCompany}
							onKeyDown={this.toLinkedIn}
							id="filled-size-small"
							ref="company"
							variant="outlined"
							size="small"
							style={styles.inputItem}
							value={this.props.company}
						/>
						<PearTextField
							error={this.props.companyErrored}
							label="LinkedIn"
							onChange={this.editLinkedIn}
							onKeyDown={this.signup}
							id="filled-size-small"
							ref="linkedIn"
							variant="outlined"
							size="small"
							style={styles.inputItem}
							value={this.props.linkedIn}
						/>
						<div style={styles.switchArea}>
							<h1 style={styles.switchLabel}>
								{this.props.isInvestor ? "I am an investor" : "I am not an investor"}
							</h1>
							<PearSwitch
								checked={this.props.isInvestor}
								onChange={this.props.editIsInvestor}
								name="isInvestor"
								inputProps={{ 'aria-label': 'are you an investor?' }}
							/>
						</div>
						{ this.props.isInvestor &&
							<div style={styles.investorFields}>
								{/* <div style={styles.switchArea}>
									<h1 style={styles.switchLabel}>
										Do you mostly lead investments?
									</h1>
									<PearCheckbox
										checked={this.props.isLead}
										onChange={this.props.editIsLead}
										name="isInvestor"
										inputProps={{ 'aria-label': 'are you an investor?' }}
									/>
								</div> */}
								{/* <Autocomplete
									multiple
									id="checkboxes-tags-demo"
									options={this.props.sectors}
									disableCloseOnSelect
									getOptionLabel={(option) => option.name}
									renderOption={(option, { selected }) => (
										<React.Fragment>
											<Checkbox
												icon={icon}
												checkedIcon={checkedIcon}
												style={{ marginRight: 8 }}
												checked={selected}
											/>
											{option.name}
										</React.Fragment>
									)}
									style={styles.inputItem}
									renderInput={(params) => (
										<PearTextField {...params} error={this.props.selectedSectorsErrored} variant="outlined" label="Investment Sectors" placeholder="" size="small" />
									)}
									value={this.props.selectedSectors}
									onChange={this.props.editSectors}
								/> */}
								{/* <PearTextField
									label=""
									id="filled-size-small"
									variant="outlined"
									size="small"
									style={styles.inputItem}
								/> */}
								{/* <PearFormControl size="small" style={styles.inputItem} variant="outlined">
									<InputLabel id="demo-simple-select-outlined-label">Most Typical Check Size</InputLabel>
									<Select
										error={this.props.checkSizeErrored}
										value={this.props.checkSize}
										onChange={this.props.editCheckSize}
										inputProps={{
											name: 'age',
											id: 'filled-age-native-simple'
										}}
										label="Average Check Size"
									>
										<MenuItem value={0}>Less than $100k</MenuItem>
										<MenuItem value={100000}>$100k - $500k</MenuItem>
										<MenuItem value={500000}>$500k - $1M</MenuItem>
										<MenuItem value={1000000}>$1M - $3M</MenuItem>
										<MenuItem value={3000000}>$3M - $10M</MenuItem>
										<MenuItem value={10000000}>$10M+</MenuItem>
									</Select>
								</PearFormControl> */}
								{/* <PearFormControl size="small" style={styles.inputItem} variant="outlined">
									<InputLabel id="demo-simple-select-outlined-label">Most Typical Fundraising Type</InputLabel>
									<Select
										error={this.props.fundraisingStageErrored}
										value={this.props.fundraisingStage}
										onChange={this.props.editFundraisingStage}
										inputProps={{
											name: 'age',
											id: 'filled-age-native-simple'
										}}
										label="Most Typical Fundraising Type"
									>
										<MenuItem value={'angel'}>Angel</MenuItem>
										<MenuItem value={'pre-seed'}>Pre-Seed/Seed</MenuItem>
										<MenuItem value={'series-a'}>Series-A</MenuItem>
										<MenuItem value={'late-stage'}>Series-B and Beyond</MenuItem>
									</Select>
								</PearFormControl> */}
							</div>
						}
					</div>
				}
				{ this.props.showSubmit &&
					<div onClick={this.props.emailState < 2 ? this.props.submit : this.props.signup} style={styles.button}>{this.props.emailState < 2 ? "Submit" : "RSVP"}</div>
				}
			</div>
		);
	}

	checkSubmit(e) {
		if (e.keyCode === 13 && this.props.showSubmit) {
			this.props.submit();
		}
	}

	editCompany(e) {
		this.props.editCompany(e.target.value);
	}

	editEmail(e) {
		this.props.editEmail(e.target.value);
	}

	editFirstName(e) {
		this.props.editFirstName(e.target.value);
	}

	editLastName(e) {
		this.props.editLastName(e.target.value);
	}

	editLinkedIn(e) {
		this.props.editLinkedIn(e.target.value);
	}

	signup(e) {
		if (e.keyCode === 13) {
			this.props.signup();
		}
	}

	toLastName(e) {
		if (e.keyCode === 13) {
			this.refs.lastName.focus();
		}
	}

	toCompany(e) {
		if (e.keyCode === 13) {
			this.refs.company.focus();
		}
	}

	toLinkedIn(e) {
		if (e.keyCode === 13) {
			this.refs.linkedIn.focus();
		}
	}
}

export default Radium(LoginHeader);