import axios from "axios";
import { authRequestWith, endpointFrom, requestWith } from './Config';

export async function authorize() {
	let res = await authRequestWith("authenticate", "get");
	if (!res || res.status > 300) {
		localStorage.setItem("ptoken", null);
		localStorage.setItem("name", null);
		localStorage.setItem("token", null);
		return false;
	} else {
		let data = await res.data;
		localStorage.setItem("name", data.firstName || data.email);
		return true;
	}
};

export async function login(key) {
	try {
		console.log(key);
		let res = await requestWith("login", "post", {
			key: key
		});
		if (res.status > 300) {
			return false;
		} else {
			localStorage.setItem("name", res.data.firstName || res.data.email);
			localStorage.setItem("token", res.data.token);
			window.heap.identify(res.data.email, 'email');
			return true;
		}
	} catch (e) {
		return false;
	}
}

export async function requestLogin(email) {
	try {
		let res = await axios.post(endpointFrom("requestLogin"), {
			email: email
		});
		if (res.status > 300) {
			return false;
		} else {
			return true;
		}
	} catch (e) {
		return false;
	}
}

export async function signup(email, company, firstName, lastName, sectors, checkSize, fundraisingStage, isLead, linkedIn, isInvestor) {
	try {
		console.log(sectors);
		let res = await axios.post(endpointFrom("signup"), {
			email: email,
			company: company,
			firstName: firstName,
			lastName: lastName,
			sectors: sectors,
			checkSize: checkSize,
			fundraisingStage: fundraisingStage,
			isLead: isLead,
			linkedIn: linkedIn,
			isInvestor: isInvestor
		});
		if (res.status > 300) {
			return false;
		} else {
			return true;
		}
	} catch (e) {
		return false;
	}
}

export async function sectors() {
	let res = await authorize("sectors", "get");
	if (res.status > 300) {
		return [];
	} else {
		let data = await res.data;
		return data;
	}
}